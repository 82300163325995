<template>
  <div class="row mx-0 h-100">
    <div class="col-12 p-0 card_item h-100">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-md-5 p-0" ref="theImage">
          <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="img-fluid fill" />
          <!-- <img :src="url + '/static/uploads/images/' + project.imageUrl" class="img-fluid fill" /> -->
        </div>
        <div class="col-md-7 p-3">
          <div class="row mx-0">
            <div class="col-12 p-0 title mt-3 mb-2 text-center text-md-start text-uppercase">
              {{project.name}}
            </div>
            <div class="col-12 p-0 date mb-4 text-center text-md-start">
              {{date}}
            </div>
            <div class="col-12 p-0 view text-center text-md-start">
              <span class="cursor" @click="goToProject">View Project</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('../components/Image.vue'))
  },
  name: 'ProjectCard',
  props: ['project'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(this.project.lastModified)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    goToProject () {
      window.scroll(0, 0)
      this.$router.push('/project/' + this.project.id)
    }
  }
}
</script>

<style scoped>
.fill {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 0;
}
.card_item {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--green-color);
}
.date {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--green-color-light);
}
.view {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--gold-color);
  text-transform: uppercase;
}
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
@media (min-width: 992px) {
  .fill {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card_item {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  }
}

</style>
